

.business-page-jobs__title {
  font-size: 2rem;
  font-weight: bolder;
  color: black;
}

.text--center {
  text-align: center;
}

.margin-left-right-5{
  margin-left: 5px;
  margin-right: 5px;
}

.page-career--container {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

.page-career--item {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: center;
  padding: 15px;
  box-shadow: 0px 4px 10px rgba(24, 25, 31, 0.03);
  border-radius: 10px;
  width: 60%;
  margin: 20px 20%;
  background: #EBF0FF;

}

.page-career__position-wanted {
  color: #6F91FC;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  margin: 0px 0px;
}

.page-career__date {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #18191F;
  margin: 0px 0px;
}

.page-career__description{
  color: #747579;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.02em;
  margin-bottom: 18px;
}

.center{
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: center;
}
.page-career__title{
  height: 44px;
  border-radius: 40px;
  border: none;
  margin: 0px 16px;
  color: white;
  font-size: 2rem;
  font-weight: bolder;

}