.social-network-item{
  height:35px;
  width:35px;
  margin-left: 10px;
  margin-right: 10px;
}

.social-network {
  width: 45vw;
  max-width: 600px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.social-network-mobile {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
}