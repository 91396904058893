.navbar {
  position: fixed;
  width: 100vw;
  height: 72px;
  left: 0px;
  top: 0px;
  background-color: white;
  //background-image: url("https://static.myshortlist.co/banner.svg");
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 1000000;
  padding-left: 12vw;
  padding-right: 11vw;
  box-shadow: 0px 3px 5px 0px rgb(0 0 0 / 18%);
  @include flashcard-margin-proportional {
    padding-left: calc((100vw - 1050px) / 2);
    padding-right: calc((100vw - 1050px) / 2);
  }
}

.navbar-on-mobile {
  position: fixed;
  width: 100vw;
  height: 72px;
  left: 0px;
  bottom: 0px;
  background-color: #F2F5FF;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 1000000;
}

.navbar-on-video-mobile {
  position: fixed;
  width: 100vw;
  height: 72px;
  left: 0px;
  bottom: 0px;
  background-color: white;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 1000000;
}

.navbar_left {
  //position: absolute;
  width: 120px;
  height: 32px;
  //left: 100px;
  //top: 20px;

}


.logo-mobile {
  position: absolute;
  left: 40vw;
  top: 35px;
  z-index: 300000000;
}
.dashboard-mobile{
  height: 20px;
  width:20px;
  margin-left: 5px;
}

.logo-mobile-new_account {
  position: fixed;
  left: 40vw;
  top: 35px;
  z-index: 300000000;
}

.button-all-jobs-tel {
  height: 30px;
  background: white;
  border-radius: 10px;
  border:none;
  margin: 0px 5px;
  font-size: 14px;
  font-weight: bold;
  color: #063EF9;
  position: absolute;
  top: 5px;
  left: 5px;
}

.button-next-tel{
  height: 30px;
  background: white;
  border-radius: 10px;
  border:none;
  margin: 0px 5px;
  font-size: 14px;
  font-weight: bold;
  color: #063EF9;
  position: absolute;
  top: 5px;
  right: 5px;
}

.flash-blue {
  margin-left: 5px;
}


.next-candidate-navbar {
  width: 262px;
  height: 44px;
  border-radius: 40px;
  margin: 0px 16px;
  font-size: 14px;
  font-weight: bold;
  background: white;
  border: 1px solid black;
  color: black;
}

.next-candidate {
  background: white;
  border-radius: 40px;
  border: 1px solid black;
  color: black;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 44px;
  width: 20vw;
  max-width: 270px;
  margin-bottom: 24px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 10px;


}

.button-select-candidate-navbar {
  width: 264px;
  height: 44px;
  background: #063EF9;
  border-radius: 40px;
  border: none;
  margin: 0px 16px;
  font-size: 14px;
  font-weight: bold;
  color: white;
}

.button-select-candidate-navbar-height {
  width: 264px;
  height: 44px;
  background: #063EF9;
  border-radius: 40px;
  border: none;
  margin: 0px 16px;
  font-size: 14px;
  font-weight: bold;
  color: white;
  @include laptops-and-bigger-only {
    position: relative;
    top: -10px;
  }
}

.button-select-candidate {
  background: #063EF9;
  border-radius: 40px;
  border: none;
  color: white;
  font-size: 14px;
  font-weight: bold;
  height: 44px;
  margin-top: 24px;
  margin-left: 2%;
  margin-right: 2%;
  width: 20vw;
  max-width: 270px;

}

.button-select-candidate__img-big {
  margin: 5px;
  height: 20px;
  width: 20px;
}

.button-select-candidate__img {
  margin: 5px;
  height: 10px;
  width: 10px;
}

.button-next-candidate-mobile__img {
  height: 20px;
  width: 20px;
  margin: 5px;
}

.button-next-candidate__img {
  margin: 5px;
  height: 10px;
  width: 10px;
}


.container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  //background-color: #E5E5E5;
  background-color: #F2F5FF;

}

.container-smartphones-tablets {
  background-color: white;
  padding: 15px;
  position: absolute;
  left: 0px;
  top: 90vw;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  z-index: 10000;

}

.container-left {
  width: 50vw;
  max-width: 640px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  padding-top: 92px;
  margin-left: 10vw;
  @include flashcard-margin-proportional {
    margin-left: calc((100vw - 1050px) / 2);
  }

  //@include flashcard-small-margin{
  //  margin-left: 10vw;
  //}
  //@include flashcard-medium-margin{
  //  margin-left: 20vw;
  //}
  //@include flashcard-large-margin{
  //  margin-left: 30vw;
  //}

}

.container-right {

  width: 25vw;
  max-width: 330px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 102px;
  position: sticky;
  //right: px;
  top: 0px;
  background: #FFFFFF;
  box-shadow: 0px 4px 10px rgba(24, 25, 31, 0.03);
  border-radius: 10px;
  padding: 30px;
  margin-right: 5vw;
  margin-left: 5vw;
  @include flashcard-margin-proportional {
    margin-right: calc((100vw - 1050px) / 2);
  }


  //@include flashcard-small-margin{
  //  margin-right: 10vw;
  //}
  //@include flashcard-medium-margin{
  //  margin-right: 20vw;
  //}
  //@include flashcard-large-margin{
  //  margin-right: 30vw;
  //}

}

.section {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: center;
  padding: 15px;
  background: #FFFFFF;
  box-shadow: 0px 4px 10px rgba(24, 25, 31, 0.03);
  border-radius: 10px;
  width: 100%;
  margin: 10px;

}

.section-on-mobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 100%;
  margin: 32px 0px;
}

.section-right {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  padding: 15px;
  background: #FFFFFF;
  box-shadow: 0px 4px 10px rgba(24, 25, 31, 0.03);
  border-radius: 10px;
  width: 100%;
  margin: 10px;
}

.section-video {
  //display: flex;
  //flex-flow: column nowrap;
  //align-items: center;
  //justify-content: center;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  position: relative;
}

.section__title {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #18191F;
  margin-right: 40px;
  margin-left: 40px;
  margin-top: 32px;
}

.section__sub-title{
  font-style: normal;
  font-weight:600;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #18191F;
  margin-left: 10px;
  margin-top: 32px;
}

.section__title-on-mobile {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #18191F;
  flex: none;
  margin: 20px 5px;
  position: relative;
}

.section__title-social-network{
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #18191F;
  margin-right: 40px;
  margin-left: 40px;

}

.section__content {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #747579;
  margin: 16px 40px 40px 32px;
  position: relative;
  text-align: justify;
}

.section__content_social-network {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #747579;
  margin-bottom: 20px;
  margin-top: 20px;
  position: relative;
}

.section-social-network {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: center;
  background: #FFFFFF;
  box-shadow: 0px 4px 10px rgba(24, 25, 31, 0.03);
  border-radius: 10px;
  width: 100%;
  margin: 10px;
}

.section__content-on-mobile {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #747579;
  margin: 16px 21px 14px 16px;
  text-align: justify;
}

.flashcard__title {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-right: 40px;
  margin-left: 40px;
  margin-top: 32px;

}

.flashcard__title-on-mobile {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin: 4px 0px;
}

.flashcard {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #18191F;
}

.flashcard__content {
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 22px;
  letter-spacing: -0.03em;
  color: #747579;
}

.flashcard__content-date {
  font-weight: bold;
}


.flashcard__title__img {
  position: static;
  width: 72px;
  height: 72px;
  left: 0px;
  top: 0px;
  background: transparent;
  border-radius: 10px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-right: 24px;
}

.flashcard__title__img-on-mobile {
  position: static;
  width: 50px;
  height: 50px;
  left: 0px;
  top: 0px;
  background: transparent;
  border-radius: 10px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-right: 5px;
}

.flashcard--navbar {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-top: 32px;
  margin-left: 32px;
  width: 80%;

}

.flashcard--navbar-on-mobile {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin: 0px 4px;

}

.flashcard--navbar__item {
  height: 30px;
  background: #F3F4F4;
  border-radius: 20px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin: 5px;
  padding: 10px;
  color: black;
  font-size: 10px;
  font-weight: bold;
}

flashcard--navbar__item-on-mobile {
  height: 30px;
  background: #F3F4F4;
  border-radius: 10px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin: 2px;
  padding: 3px;
  color: black;
  font-size: 8px;
  font-weight: bold;
}

.flashcard--navbar__item__circle {
  position: static;
  width: 8px;
  height: 8px;
  left: 10px;
  top: 13px;
  margin: 0px 5px;
  border-radius: 50%;
}

.feeling {
  background: #FF5DA1;
}

.wants {
  background: #6F91FC;
}

.matching {
  background: #063EF9;;
}

.experience {
  background: #6F91FC;
}

.feeling--container {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: center;
  padding: 15px;
  box-shadow: 0px 4px 10px rgba(24, 25, 31, 0.03);
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
  border-left: 5px solid #FF5DA1;
  background: #FFEFF6;
  margin-top: 10px;

  @include laptops-and-bigger-only {
    margin: 10px;
  }

}

.feeling__title {
  font-weight: bold;
  color: #FF5DA1;
  margin-right: 40px;
  margin-left: 40px;
  margin-top: 32px;
}

.feeling__title-on-mobile {

  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #FF5DA1;
  margin: 4px 0px;
}

.wants--container {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: center;
  padding: 15px;
  box-shadow: 0px 4px 10px rgba(24, 25, 31, 0.03);
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
  border-left: 5px solid #6F91FC;
  background: #EBF0FF;
  margin-top: 10px;
  margin-bottom: 10px;

  @include laptops-and-bigger-only {
    margin: 10px;
  }

}


.wants__title {
  font-weight: bold;
  color: #6F91FC;
  margin-right: 40px;
  margin-left: 40px;
  margin-top: 32px;
}

.wants__title-on-mobile {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #6F91FC;
  margin: 4px 0px;
}

.experience--item {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: center;
  padding: 15px;
  box-shadow: 0px 4px 10px rgba(24, 25, 31, 0.03);
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  width: 32vw;
  max-width: 475px;
  margin-bottom: 15px;
  background: #EBF0FF;
  position: relative;
  cursor: pointer;

}

.experience--arrow {
  position: absolute;
  right: 15px;
  top: 16px;
}

.experience--item-on-mobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px;
  background: #EBF0FF;
  border-radius: 0px 10px 10px 0px;
  margin: 5px 0px;
  width: 100%;
  position: relative;
  cursor: pointer;
}

.experience__position {
  color: #6F91FC;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  margin: 0px 0px;
}

.experience__position-on-mobile {

  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #6F91FC;
}

.experience__company {

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #18191F;
  margin: 0px 0px;

}

.experience__company-on-mobile {

  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #18191F;
  margin: 0px 0px;
}

.experience__company--description {
  color: #747579;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.02em;
  margin-bottom: 18px;
}

.experience__company--description-on-mobile {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #747579;
  margin-bottom: 12px;
}

.experience__candidate-mission {

  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #18191F;
  margin: 0px 12px;

}

.experience--container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}

.experience__key {
  background: #6F91FC;
  border-radius: 3px;
  padding: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  margin-right: 6px;
  margin-left: 6px;
  margin-top: 29px;
}

.experience__key__img {
  height: 17px;
  width: 17px;
  margin-right: 5px;
}

.experience-row {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;

}

.matching-score__img {
  height: 17px;
  width: 17px;
  margin-left: 5px;
}

.video-or-avatar {
  height: 250px;
  background-color: black;
  border-radius: 10px;

}

.video-or-avatar-on-mobile {
  height: 417px;
  width: 100vw;
  background-color: black;
  border-radius: 10px;


}

.contact {

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  position: absolute;
  width: 4vw;
  height: 10vw;
  max-height: 120px;
  right: 4px;
  top: 3vw;
  filter: drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.05));
  z-index: 200;
  //background-color: red;
}

.contact-on-mobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  position: absolute;
  width: 32px;
  //height: 135.93px;
  right: 32px;
  top: 50px;
  filter: drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.05));
}


.contact__item-on-mobile {
  height: 9vw;
  width: 9vw;
  position: relative;
  margin: 3vw;
  border-radius: 50%;
  background-color: white;
}

.information__img {
  height: 25px;
  width: 25px;
  // position: relative;
  margin: 5px;
  //padding: 5px;
  //border-radius: 50%;
  //background-color: white;
  //max-height: 15px;
  //max-width: 15px;
}

.cv__img {
  height: 15px;
  width: 15px;
  padding: 5px;
  margin: 5px;
  border-radius: 50%;
  background-color: white;

}

.placeholder {
  color: white;
  margin-right: 5px;
  //background-color: rgba(255, 255, 255, 0.7);
  //border-radius: 4px;
  //padding:2px;
  //font-weight: bold;
}

.information__img-mobile {
  height: 10vw;
  width: 10vw;
  position: relative;
  margin: 3vw;
}

.information__img-mobile-popup {
  height: 10vw;
  width: 10vw;
  position: relative;
  margin-bottom: 3vw;
  margin-left: 1vw;

}

.cv__img-mobile {
  height: 6vw;
  width: 6vw;
  position: relative;
  margin: 3vw;
  padding: 2vw;
  background-color: white;
  border-radius: 50%;

}

.share-circle {
  height: 9.5vw;
  width:9.5vw;
  background-color: white;
  border-radius: 50%;
  position: relative;
  margin: 3vw;
}

.share__img-mobile {
  position: relative;
  top:2vw;
  left:0.5vw;
  height: 5vw;
  width: 5vw;

}


.information__img-mobile--linkedin {
  height: 5vw;
  width: 5vw;
  position: absolute;
  top: 2vw;
  right: 2vw;
  //margin: 3vw;
  //padding: 2vw;
  //border-radius: 50%;
  //background-color: white;
}

.salary_wanted {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 8px;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  background-color: #063EF9;
  color: white;
  position: absolute;
  right: 0px;
  bottom: 2.5vh;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  width: 15vw;
  max-width: 230px;
  height: 2vw;
  font-size: 0.8vw;

}


.salary_wanted-on-mobile {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: #063EF9;
  height: 26px;
  font-size: 10px;
  color: white;
  position: absolute;
  width: 155px;
  right: -15px;
}

.euro__img-mobile {
  margin-right: 5px;
  height: 4vw;
  width: 4vw;
}

.euro__img {

  margin-right: 5px;

  @include smartphones-only {
    height: 2vw;
    width: 2vw;
    padding: 1vw;
  }
  @include tablets-and-bigger-only {
    height: 1vw;
    width: 1vw;
    padding: 0.2vw;
  }
}

.position-wanted {
  font-style: normal;
  font-weight: 800;
  font-size: 100%;
  line-height: 22px;
  color: #063EF9;
}

.match {
  color: #FF5DA1;
  font-style: normal;
  font-weight: 900;
  font-size: 90%;
  line-height: 22px;
  text-align: right;
  letter-spacing: -0.02em;
}


.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}


.name-position-match {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin: 15px 0;
  width: 100%;
  position: relative;
}

.name-position-match--title {
  font-style: normal;
  font-weight: 600;
  font-size: 100%;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #18191F;
}

.information--item-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;

}

.information--item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 2px 2px;
  background: #EBF0FF;
  border-radius: 3px;
  margin-top: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  margin-right: 3px;
  width: 100%;
}

.information--item-on-mobile {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 2px;
  background: #EBF0FF;
  border-radius: 3px;
  margin: 3px;
  font-style: normal;
  font-weight: 500;
  font-size: 2.7vw;
  line-height: 18px;
  width: 48%;

}

.information--item--img {
  margin: 0 8px;
  width: 14px;
  height: 14px;
}

.arrow-left {
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 5px solid black;

}

.arrow-down {
  width: 0;
  height: 0;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  border-top: 5px solid black;

}

.matching-score__title {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #18191F;
  margin-left: 40px;
  margin-top: 32px;
  position: relative;
}

.matching-score__arrow-description {
  position: absolute;
  top: -50px;
  right: -320px;
  z-index: 300000;
}

.matching-score__arrow-description-mobile {
  position: absolute;
  top: -155px;
  right: 30px;
  z-index: 300000;
}

.matching-score__description {
  background: black;
  border-radius: 10px;
  color: white;
  font-size: 14px;
  padding: 10px;
  width: 298px;
  font-style: normal;
  font-weight: normal;
  line-height: 22px;
  letter-spacing: -0.02em;
  display: inline-block;
  overflow: visible;

}

.matching-score__description-mobile {
  background: black;
  border-radius: 10px;
  color: white;
  font-size: 14px;
  padding: 10px;
  width: 90%;
  font-style: normal;
  font-weight: normal;
  line-height: 22px;
  letter-spacing: -0.02em;
  display: inline-block;
  overflow: visible;

}

.display-none {
  display: none;
}

.matching-score-container {
  background: white;
  border: 2px solid #063EF9;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(24, 25, 31, 0.02);
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;


  @include smartphones-only {
    width: 100%
  }
  @include tablets-and-bigger-only {
    width: 85%;
    margin-left: 32px;
  }

}

.matching-score-navbar {
  height: 90px;
  background-color: #063EF9;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 24px;
  position: relative;
  overflow: hidden;


  @include smartphones-only {
    width: 100%
  }
  @include tablets-and-bigger-only {
    width: 85%;
    margin-left: 32px;
  }

}

.rectangle-left {
  position: absolute;
  width: 40%;
  height: 81px;
  left: -60px;
  top: 35px;
  background: #2B5BFA;
  border-radius: 10px;
  transform: rotate(-15deg);
}

.rectangle-right {
  position: absolute;
  width: 50%;
  height: 90.2px;
  right: -40px;
  top: -60px;
  background: #2B5BFA;
  border-radius: 10px;
  transform: rotate(-15deg);
}

.avatar-on-matching-score {
  height: 30px;
  width: 30px;
  background-color: black;
  border-radius: 10px;
  margin: 7px;
}


.matching-score-navbar-on-mobile {
  height: 90px;
  width: 100%;
  background-color: #063EF9;
  border-radius: 7px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 3px;
}

.matching-score-title {
  margin: 15px;
  color: white;
  font-size: 20px;
  font-weight: bold;
  z-index: 30000;
}

.matching-score-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 15px;
}

.polar-angle-axis {
  font-weight: bolder;
}

.button-matching-score {
  background-color: transparent;
  border: none;
}

.line {
  position: absolute;
  width: 24px;
  height: 0px;
  left: 176px;
  top: 17px;
  background: #18191F;
  opacity: 0.3;
  border: 2px solid #18191F;
}

.read-more {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #063EF9;
  border: none;
  background-color: transparent;
}

.avatar__img {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  margin: 5px;

}

.column {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.button-back {
  position: absolute;
  left: 10px;
  top: 24px;
  border: none;
  background-color: transparent;
  z-index: 10000;
}

.button-back-new-account {
  position: fixed;
  left: 10px;
  top: 24px;
  border: none;
  background-color: transparent;
  z-index: 10000;
}

.button-back-mobile {
  position: absolute;
  left: 10px;
  top: 15px;
  border: none;
  background-color: transparent;
  z-index: 10000;
}

.videoInsert {
  position: relative;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 100;
  background-size: cover;
  overflow: hidden;
  border-radius: 10px;

}

.matching-score__description-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
  left: -20px;
}


.button-x {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: static;
  width: 40px;
  height: 40px;
  left: 0px;
  top: 0px;
  background: rgba(255, 255, 255, 0.12);
  border: 1px solid #063EF9;
  box-sizing: border-box;
  border-radius: 50px;
  margin: 0px 12px;
  color: #063EF9;
}

.margin3 {
  margin: 0px 3px;
}

.clementine-video-mobile {
  width: 100vw;
  height: 100vw;
}

.play {
  z-index: 300000000;
  position: absolute;
  right: 45vw;
  top: 45vw;
}

.play-pc {
  z-index: 300000000;
  position: absolute;
  left: 42%;
  top: 42%;
  width: 15%;
  height: 15%;
}

.avatar-for-video {
  background-image: linear-gradient(to top, rgba(36, 22, 190, 0.3), lighten(rgba(36, 22, 180, 0.3), 16%));
  border-radius: 10px;
  width: 100%;
  height: 100%;
  z-index: 300000;
}

.img-gradient {
  position: relative;
  display: inline-block;
  border-radius: 10px;
  width: 18vw;
  max-width: 300px;
  height: 100%;
}

.img-gradient:after {
  border-radius: 10px;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  background: -moz-linear-gradient(top, rgba(36, 22, 190, 0.3) 0%, rgba(36, 22, 190, 0.3) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(36, 22, 190, 0.3)), color-stop(100%, rgba(36, 22, 190, 0.3))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(36, 22, 190, 0.3) 0%, rgba(36, 22, 190, 0.3) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgba(36, 22, 190, 0.3) 0%, rgba(36, 22, 190, 0.3) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgba(36, 22, 190, 0.3) 0%, rgba(36, 22, 190, 0.3) 100%); /* IE10+ */
  background: linear-gradient(to bottom, rgba(36, 22, 190, 0.3) 0%, rgba(36, 22, 190, 0.3) 100%); /* W3C */
}

.img-gradient img {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.img-gradient-on-mobile {
  position: relative;
  display: inline-block;
}

.img-gradient-on-mobile:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  background: -moz-linear-gradient(top, rgba(36, 22, 190, 0.3) 0%, rgba(36, 22, 190, 0.3) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(36, 22, 190, 0.3)), color-stop(100%, rgba(36, 22, 190, 0.3))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(36, 22, 190, 0.3) 0%, rgba(36, 22, 190, 0.3) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgba(36, 22, 190, 0.3) 0%, rgba(36, 22, 190, 0.3) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgba(36, 22, 190, 0.3) 0%, rgba(36, 22, 190, 0.3) 100%); /* IE10+ */
  background: linear-gradient(to bottom, rgba(36, 22, 190, 0.3) 0%, rgba(36, 22, 190, 0.3) 100%); /* W3C */
}

.img-gradient-on-mobile img {
  display: block;
  width: 100vw;
  height: 100vw;
}

.circle-blue {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #6F91FC;
  //margin: 2px;
  z-index: 30000;
  position: absolute;
  left: 2px;
  top: 2px;
}

.circle-blue-container {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 2px solid #6F91FC;
  margin-right: 10px;
  margin-top: 10px;
  background-color: white;
  z-index: 20000;
  position: relative;
}

.border-blue {
  height: 75vh;
  width: 4px;
  background-color: #6F91FC;
  position: relative;
  left: 42px;
  top: 27px;
}

.display-button {
  @include flashcard-height {
    display: none;
  }
}

.button-tel {
  border: 1px solid darkgreen;
  background-color: white;
  color: darkgreen;
  border-radius: 10px;
  font-weight: bold;
}

.button-tel-mobile {
  border: 1px solid #063EF9;
  background-color: white;
  color: #063EF9;
  border-radius: 10px;
  font-weight: bold;
}