.ellipse {
  position: fixed;
  left: -10vw;
  top: -50vh;
  height: 200vh;
  width: 50vw;
  border-radius: 50%;
  background-color: #063EF9;
  z-index: -50;

}

.ellipse-content {
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
  position: relative;
  top: 55vh;
}

.ellipse-img {
  width: 25vw;
  height: 284px;
  margin: 15vh 15vw;
}

.ellipse-description {
  width: 20vw;
  height: 112px;
}

.ellipse-description-title {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #FFFFFF;
  margin: 8px 0px;
}

.ellipse-description-content {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #FFFFFF;
  opacity: 0.7;
  margin: 8px 0px;
}

.ellipse-img-name {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #FFFFFF;
  position: relative;
  left: 8px;
}

.ellipse-img-name-mobile {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: black;
  position: relative;
  left: 8px;
}

.ellipse-img-clementine {
  position: relative;
  left: 7vw;
  width: 13vw;
  height: 13vw;
  border-radius: 10px;
  border: 2px solid white;
}

.ellipse-img-clementine-mobile {
  position: relative;
  left: 85vw;
  top: 90vh;
  width: 30vw;
  height: 30vw;
  border-radius: 10px;
  z-index: 30000000;
}

.ellipse-little-img{
  width:55px;
  height:55px;
  border-radius: 50%;
  border:2px solid white;
}


.ellipse-img-jeanne {
  position: relative;
  top: -30px;
  width: 5vw;
  height: 5vw;
}

.ellipse-img-jeanne-mobile {
  position: relative;
  top: 87vh;
  left: 60vw;
  width: 5vw;
  height: 5vw;
}

.ellipse-img-armand {
  position: relative;
  top: -50px;
  left: 11vw;
  width: 6vw;
  height: 6vw;
}

.ellipse-img-armand-mobile {
  position: relative;
  top: 90vh;
  left: 90vw;
  width: 5vw;
  height: 5vw;
}

.ellipse-img-coline {
  position: relative;
  top: -220px;
  left: 22vw;
  width: 5vw;
  height: 5vw;
}

.ellipse-img-coline-mobile {
  position: relative;
  top: 80vh;
  left: 120vw;
  width: 5vw;
  height: 5vw;
}

.new-account-container {
  position: relative;
  left: 50vw;
  top: 20vh;
  width: 35vw;
}

.new-account-container-information {
  position: relative;
  left: 50vw;
  top: 7vh;
  width: 35vw;


}

.new-account-container-demonstration {
  position: relative;
  left: 50vw;
  width: 35vw;
  top: 5vh;
}

.new-account-start__title {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #18191F;
  margin: 8px 0px;
}

.new-account-start__content {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #747579;
  margin-top: 8px;
  margin-bottom: 40px;
}

.new-account-start__button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 22px;
  width: 100%;
  height: 48px;
  border: 1px solid #C5C5C7 !important;
  box-sizing: border-box;
  border-radius: 40px !important;
  margin: 16px 0px;
  background-color: transparent;
  box-shadow: none !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 15px !important;
  line-height: 24px !important;
  color: #18191F !important;

}

.new-account-start__button-text {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #18191F;
  margin: 0px 16px;
}

.new-account-start__email-border {
  width: 100%;
  border-top: 1px solid #C5C5C7;
  margin-top: 32px;
  margin-bottom: 32px;
}

.new-account-start-mobile__email-border {
  width: 100%;
  border-top: 1px solid #C5C5C7;
  margin-top: 25px;
  margin-bottom: 20px;
}

.new-account-start__email {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #C5C5C7;
  text-align: center;
  background-color: white;
  position: relative;
  top: -41px;
  left: 10vw;
  width: 200px;
  padding-left: 10px;
  padding-right: 5px;

}

.new-account-start-mobile__email {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #C5C5C7;
  text-align: center;
  background-color: white;
  position: relative;
  top: -31px;
  left: 15vw;
  width: 200px;
  padding-left: 10px;
  padding-right: 5px;
}

.new-account-start__button-blue {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 22px;
  width: 100%;
  height: 48px;
  background: #063EF9;
  border-radius: 40px;
  border: none;

  &:disabled {
    opacity: 0.5;
  }

}


.new-account-start__button-blue-content {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #FFFFFF;
  margin: 0px 8px;
}

.new-account-start__return-flashcard {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 24px;

}


.new-account-start__return-flashcard-content {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #18191F;
  margin: 0px 4px;
}

.new-account-information {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.new-account-information-description {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #747579;
  margin: 2px 0px;
}

.new-account-information-title {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #18191F;
  margin: 2px 0px;
}

.new-account-information-item-title {
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #18191F;
}

.new-account-information-item-input {
  border: 1px solid #C5C5C7;
  box-sizing: border-box;
  border-radius: 40px;
  width: 100%;
  margin-top: 10px;
  padding: 6px 12px;

}

.new-account-information-item-input_warning{
  font-size: 10px;
  color: red;
  margin-left: 10px;
}

.new-account-information-item-select {
  box-sizing: border-box;
  border-radius: 40px;
  width: 100%;
  margin-top: 10px;
  padding: 6px 12px;
  background: transparent;
  border: 1px solid #C5C5C7;
}

.new-account-information-item {
  margin-top: 15px;
}

.button-transparent {
  background-color: transparent;
  border: none;
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
}

.calendly {
  min-width: 320px;
  height: 630px;
}

.navbar-new-account {
  width: 35vw;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
}

.navbar-new-account-button {
  background-color: transparent;
  border: none;
}

.navbar-new-account-button-mobile {
  background-color: transparent;
  border: none;
  width: 100%;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.02em;
  color: #18191F;
}

.navbar-bottom-new-account-informations {
  background: -webkit-linear-gradient(left, rgba(6, 62, 249, 1) 0%, rgba(6, 62, 249, 1) 33%, rgba(220, 221, 221, 1) 33%, rgba(220, 221, 221, 1) 66%, rgba(220, 221, 221, 1) 66%, rgba(220, 221, 221, 1) 100%);
  height: 2px;
  width: 35vw;
  margin-bottom: 20px;
}

.navbar-bottom-new-account-business {
  background: -webkit-linear-gradient(left, rgba(6, 62, 249, 1) 0%, rgba(6, 62, 249, 1) 33%, rgba(6, 62, 249, 1) 33%, rgba(6, 62, 249, 1) 66%, rgba(220, 221, 221, 1) 66%, rgba(220, 221, 221, 1) 100%);
  height: 2px;
  width: 35vw;
  margin-bottom: 20px;
}

.navbar-bottom-new-account-demonstration {
  background: -webkit-linear-gradient(left, rgba(6, 62, 249, 1) 0%, rgba(6, 62, 249, 1) 66%, rgba(6, 62, 249, 1) 66%, rgba(6, 62, 249, 1) 95%, rgba(220, 221, 221, 1) 95%, rgba(220, 221, 221, 1) 100%);
  height: 2px;
  width: 35vw;
  margin-bottom: 20px;
}

.navbar-bottom-new-account-informations-mobile {
  background: -webkit-linear-gradient(left, rgba(6, 62, 249, 1) 0%, rgba(6, 62, 249, 1) 33%, rgba(220, 221, 221, 1) 33%, rgba(220, 221, 221, 1) 66%, rgba(220, 221, 221, 1) 66%, rgba(220, 221, 221, 1) 100%);
  height: 2px;
  width: 100vw;
  margin-bottom: 20px;
}

.navbar-bottom-new-account-business-mobile {
  background: -webkit-linear-gradient(left, rgba(6, 62, 249, 1) 0%, rgba(6, 62, 249, 1) 33%, rgba(6, 62, 249, 1) 33%, rgba(6, 62, 249, 1) 66%, rgba(220, 221, 221, 1) 66%, rgba(220, 221, 221, 1) 100%);
  height: 2px;
  width: 100vw;
  margin-bottom: 20px;
}

.navbar-bottom-new-account-demonstration-mobile {
  background: -webkit-linear-gradient(left, rgba(6, 62, 249, 1) 0%, rgba(6, 62, 249, 1) 66%, rgba(6, 62, 249, 1) 66%, rgba(6, 62, 249, 1) 95%, rgba(220, 221, 221, 1) 95%, rgba(220, 221, 221, 1) 100%);
  height: 2px;
  width: 100vw;
  margin-bottom: 20px;
}

.ellipse-mobile {
  position: fixed;
  left: -50vw;
  top: -75vh;
  height: 100vh;
  width: 200vw;
  border-radius: 50%;
  background-color: #063EF9;
  z-index: 100000;
}

.new-account-information-item-input-mobile {
  border: 1px solid #C5C5C7;
  box-sizing: border-box;
  border-radius: 40px;
  width: 100%;
  margin-top: 5px;
  padding: 6px 12px;


}

.new-account-information-item-title-mobile {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #18191F;
  margin-right: 5px;
  min-width: 102px;
}

.new-account-information-item-mobile {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.new-account-creation-container-mobile {
  padding: 20px;
  position: relative;
  top: 50vh;
}

.new-account-start-mobile__title {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.02em;
  color: #18191F;
  margin: 8px 0px;
}

.new-account-start-mobile__content {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #747579;
  margin: 8px 0px;
}

.new-account-start-mobile__button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 22px;
  border: 1px solid #C5C5C7;
  box-sizing: border-box;
  border-radius: 40px;
  margin: 16px 0px;
}

.new-account-container-mobile {
  padding: 5vw;
}


.img-gradient-new-account {
  position: relative;
  display: inline-block;
  border-radius: 10px;
  left: 7vw;
  width: 13vw;
  height: 13vw;
  border: 2px solid white;
}

.img-gradient-new-account:after {
  border-radius: 10px;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  background: -moz-linear-gradient(top, rgba(36, 22, 190, 0.3) 0%, rgba(36, 22, 190, 0.3) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(36, 22, 190, 0.3)), color-stop(100%, rgba(36, 22, 190, 0.3))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(36, 22, 190, 0.3) 0%, rgba(36, 22, 190, 0.3) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgba(36, 22, 190, 0.3) 0%, rgba(36, 22, 190, 0.3) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgba(36, 22, 190, 0.3) 0%, rgba(36, 22, 190, 0.3) 100%); /* IE10+ */
  background: linear-gradient(to bottom, rgba(36, 22, 190, 0.3) 0%, rgba(36, 22, 190, 0.3) 100%); /* W3C */
}

.img-gradient-new-account img {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 10px;

}

.img-gradient-new-account-mobile {
  position: relative;
  display: inline-block;
  border-radius: 10px;
  left: 85vw;
  top: 90vh;
  width: 30vw;
  height: 30vw;
}

.img-gradient-new-account-mobile:after {
  border-radius: 10px;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  background: -moz-linear-gradient(top, rgba(36, 22, 190, 0.3) 0%, rgba(36, 22, 190, 0.3) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(36, 22, 190, 0.3)), color-stop(100%, rgba(36, 22, 190, 0.3))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(36, 22, 190, 0.3) 0%, rgba(36, 22, 190, 0.3) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgba(36, 22, 190, 0.3) 0%, rgba(36, 22, 190, 0.3) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgba(36, 22, 190, 0.3) 0%, rgba(36, 22, 190, 0.3) 100%); /* IE10+ */
  background: linear-gradient(to bottom, rgba(36, 22, 190, 0.3) 0%, rgba(36, 22, 190, 0.3) 100%); /* W3C */
}

.img-gradient-new-account-mobile img {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 10px;

}

.logo-white-and-black{
  width:150px;
}
