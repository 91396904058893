.video-div{
  width:100vw;
  height: 100vh;
  background-color: #22198A;
}

.navbar-white {
  position: fixed;
  width: 100vw;
  height: 72px;
  left: 0px;
  top: 0px;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
  z-index: 1000000;
  padding-left: 12vw;
  padding-right: 11vw;
  @include flashcard-margin-proportional{
    padding-left: calc((100vw - 1050px) / 2);
    padding-right: calc((100vw - 1050px) / 2);
  }
}

.next-candidate-navbar-white{
  background: white;
  border-radius: 40px;
  border: 1px solid black;
  color: black;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 44px;
  width: 19vw;
  margin-left: 5px;
  margin-right: 5px;
  position:relative;
  left:0px;
}

.select-candidate-navbar-white{
  background: #063EF9;
  border-radius: 40px;
  border: none;
  color: white;
  font-size: 14px;
  font-weight: bold;
  height: 44px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  margin-right: 5px;
  width: 19vw;
}

.display-video-pc-container{
  background-color:#F2F5FF;
  //background-color: #181818;
  width:100vw;
  height:100vh;

}

.display-video-mobile-container{
  background-color:white;
  //background-color: #181818;
  width:100vw;
  height:100vh;

}

.video-container{
  position:absolute;
  top:10vw;
  left:20vw;
  width:60vw;
  height: 28.5vw;
  overflow: hidden;
  display:block;
  border-radius: 10px;


}



.video-container-mobile{
  position:absolute;
  top:30%;
  left:0vw;
  width:100vw;
  height: 47.5vw;
  overflow-y: hidden;
  overflow-x: hidden;
  border-radius: 10px;
}

.video-content{
  position:relative;
  top:-1vw;
  left:0px;
}
.video-content-mobile{
  position:relative;
  top:-3vw;
  left:0px;
}

.video-pc{
  width:440px;
  height: 200px;
  object-fit: cover;
  //object-position: -5px -5px -5px -5px;
  object-position: -5px -5px;
  //border-radius:10px;
  //padding: 10px;
  position: absolute;
  top:15vh;
  left:20vw;
  background-color: transparent;
}

.video-mobile{
  width:100vw;
  position: absolute;
  top:30vh;
  background-color: transparent;
}



.video-close{
  position: absolute;
  right: 150px;
  top: 128px;
  background-color: transparent;
  border:none;
}

.video-whithout-container{
  position:absolute;
  top:10vw;
  left:20vw;
  width:60vw;
  border-radius: 10px;
}

.video-whithout-container-mobile{
  position:absolute;
  top:50vw;
  width:100vw;
  border-radius: 10px;
}