.page-apply-position-wanted {
  width: 20vw;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 34px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #FFFFFF;
  position:absolute;
  top:30vh;
}

.page-apply-position-wanted-mobile {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #FFFFFF;
  width:90vw;
  position: relative;
  top:3vh;

}

.page-apply-description {
  position: relative;
  top: 55vh;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
}

.page-to-apply-container {
  position: relative;
  left: 50vw;
  top: 10vh;
  width: 35vw;
}

.page-to-apply-success-container{
  position: relative;
  left: 50vw;
  top: 40vh;
  width: 35vw;
}

.page-to-apply-success-container-mobile{
  position: relative;
  top: 30vh;
  width: 80vw;
  padding-top: 15vh;
  padding-left: 0vw;
}


.page-apply-rocket-img {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

.page-apply-rocket-text {
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 30px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #FF5DA1;
  opacity: 1;
}

.page-apply-rocket{
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top:80vh;
}

.page-apply-img-gabrielle{
  width:50px;
  height: 50px;
  margin-left: 20px;
}

.page-apply-success-title {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #18191F;
  margin: 0px 10px;
}

.page-apply-success-description{
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #747579;
  margin: 2px 10px;
}
.page-apply-success-description-mobile{
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #747579;
  margin-left: 15vw;
  margin-top: 2vh;
}

.page-apply-description-mobile {
  position: relative;
  top: 80vh;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  z-index:1000000;
}

.page-to-apply-container-mobile{
  position: relative;
  left: 0vw;
  top: 30vh;
  width:80%;
  margin-left: 10%;
}

.page-apply-warning{
  text-align: center;
  font-size: 0.8rem;
  color: #e51e1e;
  margin-bottom: 5px;
}