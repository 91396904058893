.loading-animation--fullpage{
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.loader {
  position: relative;
  width: 18px * 3 +12px;
  height: 12px;
  margin: 18px auto;
  z-index: 100;
}