.client-page__container {
  background-color: #F2F5FF;
}

.client-page__navbar-business-page-mobile {
  width: 100vw;
  height: 50px;
  background-color: white;
  position: fixed;
  top: 0px;
  z-index: 300000000;
  box-shadow: 0px 3px 5px 0px rgb(0 0 0 / 18%);


}

.subnav {
  text-align: center;
  overflow: hidden;
  border-style: solid;
  border-width: 1px;
  border-color: #063EF9;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 7px 14px 0 rgb(8 15 52 / 3%);
  width: 50%;
  padding: 20px;
  margin-left: 25%;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
}

.subnav-mobile {
  margin-top: 10px;
  text-align: center;
  overflow: hidden;
  border-style: solid;
  border-width: 1px;
  border-color: #063EF9;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 7px 14px 0 rgb(8 15 52 / 3%);
  width: 96%;
  padding: 10px;
  margin-left: 2%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.filter-business-page {

  min-height: 68px;
  margin-right: 30px;
  margin-bottom: 0px;
  margin-left: 10px;
  padding-right: 22px;
  padding-left: 22px;
  border-style: solid;
  border-width: 1px;
  border-color: #063EF9;
  border-radius: 10000px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

}

.filter-business-page-mobile {
  min-height: 35px;
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-right: 5px;
  padding-left: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: #063EF9;
  border-radius: 10000px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  width: 200px;
}

.filter-business-page_img {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

.filter-business-page_img-canceled {
  height: 15px;
  width: 15px;
  margin-right: 10px;
}

//
//.client-page-navbar{
//  background: transparent;
//  border: none;
//  margin: 0px 8px;
//  font-size: 25px;
//  font-weight: bold;
//  color: #063EF9;
//}

.client-page__mission__items {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: flex-start;
}

.client-page__mission__items-mobile {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
}

.client-page__mission {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 25px;
  margin: 30px auto;
  padding: 19px 22px;
  max-width: 1100px;
  border: 2px solid white;
}

.client-page__mission-mobile {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 25px;
  margin: 15px 15px !important;
  padding: 9px 12px;
  border: 2px solid white;
}

.client-page__mission__left {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.client-page__mission__left-mobile {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.client-page__mission__candidates-number {
  width: 90px;
  height: 60px;
  border-right: 2px solid grey;
  margin-right: 30px;
  padding-right: 10px;
  color: #ccc;
  font-weight: bolder;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  font-size: 2rem;

  & span {
    font-size: 1rem !important;
  }
}

.client-page__mission__item {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  padding: 8px;
  margin-right: 8px;
}

.client-page__mission__item--title {
  font-size: 0.7rem;
  color: gray;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.client-page__mission__item--title--img {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.client-page__mission__item--content {
  font-size: 0.9rem;
  font-weight: bold;
}

.client-page__title {
  font-weight: 300;
  text-align: center;
  font-size: 37px;
  color: #063EF9;

}

.client-page__title-mobile {
  font-weight: bold;
  text-align: center;
  font-size: large;
  color: #063EF9;
  margin-top: 20px;
}

.client-page__avatar {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  object-fit: cover;

  @include smartphones-only {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
  }
}


.client-page__candidate {
  margin: 12px;
  padding: 15px;
  border-radius: 25px;
  background-color: #F2F5FF;
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: flex-start;

  & > * {
    margin: 0 20px;
  }

  @include smartphones-only {
    margin: 5px;
    padding: 5px;
    border-radius: 10px;
    background-color: #F2F5FF;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
}

.client-page__candidate__name {
  font-size: 1.2rem;
  font-weight: bolder;
  color: #063EF9;
}

.client-page__candidate__block__name {
  min-width: 250px;
  @include smartphones-only {
    min-width: 0px;
  }
}

.client-page__candidate--recruited {
  margin: 12px;
  padding: 15px;
  border-radius: 25px;
  background-color: #F2F5FF;
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: flex-start;

  & > * {
    margin: 0 20px;
  }

  @include smartphones-only {
    margin: 5px;
    padding: 5px;
    border-radius: 10px;
    background-color: #F2F5FF;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }

}

.client-page__evaluation {
  width: 150px;
}

.client-page__mission__status {

  padding: 10px 15px;
  border-radius: 25px;
  font-weight: bolder;
  font-size: 0.95rem;
  color: #063EF9;
}

.client-page__date {
  background-color: #eee;
  font-size: 0.9rem;
  padding: 5px 10px;
  border-radius: 20px;
}

.client-page__date__job-title {
  color: #063EF9;
  font-size: 1.2rem;
  font-weight: bolder;
}

.client-page__filters {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.client-page__filter {
  padding: 10px 20px;
  border-radius: 30px;
  margin: 0 10px 0 10px;
  border: 1px solid #ccc;
  background-color: white;
}

.client-page__mission__avatars {

  & img {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin: 7px 5px;
    object-fit: cover;
  }
}

.client-page__candidate-recruited {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  align-items: center;
  margin: 10px;
  background-color: white;
  border-radius: 20px;
  width: 250px;
  height: 300px;
  padding: 30px;
}

.client-page__candidates-recruited {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

.client-page__candidate-recruited__positionWanted {
  text-align: center;
}

.filter-title {
  padding-left: 10px;
  color: #3898ec;
}

.client-page__btn {
  background: #063EF9;
  border-radius: 20px;
  border: none;
  color: white;
  font-size: 15px;
  font-weight: bold;
  height: 30px;
  margin-top: 10px;
  margin-left: 10px;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.client-page-navbar {
  position: fixed;
  width: 100vw;
  height: 72px;
  left: 0px;
  top: 0px;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  z-index: 1000000;
  box-shadow: 0px 3px 5px 0px rgb(0 0 0 / 18%);
}

.client-page-navbar-left {
  //margin-left: 5vw;
}

.client-page-navbar-right {
  width: 10vw;
}

.client-page__delete-button {
  background-color: transparent;
  border: none;
  position: absolute;
  right: 30px;

}

.client-page__delete-button-img {
  width: 50px;
  height: 50px;
}
