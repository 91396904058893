.window {

  padding: 22px 25px 40px 25px;
  width: 500px;
  max-height: 96vh;
  background-color: white;
  border-radius: 20px;
  overflow-y: auto;

  @include smartphones-only {
    padding: 22px 30px 50px 30px;
    width: 90vw;
    max-height: 90vh;
    margin: auto;
  }
}

.window--big {
  padding: 22px 25px 40px 25px;
  width: 95%;
  max-width: 960px;
  max-height: 94vh;
  background-color: white;
  border-radius: 20px;
  overflow-y: auto;

  @include smartphones-only {
    padding: 22px 30px 50px 30px;
    width: 90vw;
    max-height: 94vh;
    margin: auto;
  }
}



.window::-webkit-scrollbar {
  display: none;
}

.window__header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  &__logo {
    font-family: 'Pacifico', cursive;
    font-size: 1rem;
    color: #3a76ac;;
  }
}

.window__title {
  text-align: center;
  margin: 20px auto 40px auto;
  font-weight: 800;
  font-size: 1.3rem;
  color: #444;
}

.window {
  &--appear {
    animation: window-appear 0.3s forwards;
  }

  &--disappear {
    animation: window-disappear 0.3s forwards;
  }
}

@keyframes window-appear {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes window-disappear {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.8);
  }
}

.window {

  padding: 22px 30px 50px 30px;
  width: 520px;
  max-height: 96vh;
  background-color: white;
  overflow-x: hidden;
  border-radius: 10px;
  @include smartphones-and-tablets-only {
    padding: 22px 30px 50px 30px;
    width: 90vw;
    max-height: 90vh;
    margin: auto;
  }

  &__section {

    margin: 40px auto;
  }

}

.window-container {

  z-index: 3000000000000000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(47, 47, 47, 0.51);
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  transition: background-color 0.15s;

}

.animation-fade-in {
  opacity: 0;
  animation: fade-in 0.3s forwards;

  &--faster {
    opacity: 0;
    animation: fade-in 0.2s forwards;
  }

  &--slow {
    opacity: 0;
    animation: fade-in 1s forwards;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

