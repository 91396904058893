
.tchat-container{
  width: 100%;
  height:200px;
  background-color: white;
  margin:2px;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  //border:2px solid #063EF9;
  border-radius: 10px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.tchat-container::-webkit-scrollbar {
  display: none;
}

.tchat-item{
  margin:5px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.tchat-avatar{
  width:30px;
  height: 30px;
  border-radius:50%;
  font-size: small;
  font-weight: bold;
  border: 2px solid black;
  margin:10px;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;

}

.tchat-avatar-img{
  width:40px;
  height: 40px;
  border-radius: 20px;
}

.tchat-title{
  font-weight: bold;
  color: black;
}

.tchat-input{
  width: 250px !important;
  border: 1px solid black !important;
  background-color: white;
  border-radius:40px !important;
  margin: 2px 16px;
  padding: 10px 18px;
  color:black;
  height: 44px;
  font-size: 14px;
  font-weight: bold;

}

.tchat-input-tel{
  width: 200px !important;
  border: 1px solid black !important;
  background-color: white;
  border-radius:20px !important;
  margin: 2px;
  padding: 10px;
  color:black;
  height: 30px;
  font-size: 12px;
  font-weight: bold;

}

.button-tchat {
  width: 150px;
  height: 44px;
  background: #063EF9;
  border-radius: 40px;
  border: none;
  margin: 0px 16px;
  font-size: 14px;
  font-weight: bold;
  color: white;
  &:disabled {
    opacity: 0.5;
  }
}

.button-tchat-tel {
  width: 90px;
  height: 30px;
  background: #063EF9;
  border-radius: 20px;
  border: none;
  margin: 0px 5px;
  font-size: 12px;
  font-weight: bold;
  color: white;
}

.share-candidate{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @include smartphones-only{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
